import * as React from "react";

import { Drawer, DrawerProps, Menu, MenuProps } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { HEADER_MENU } from "../../../../core/models/constants/Core.Constants";
import { Link } from "react-router-dom";

const iconPath = process.env.PUBLIC_URL + '/images/';
type SideDrawerProps = {
  visible: boolean,
  onClose: () => void
  updateSlectedKeys: (value: string) => void;
  selectedKeys: string[]
}

const SideDrawer: React.FC<SideDrawerProps> = ({ visible, onClose, updateSlectedKeys, selectedKeys }) => {
  const placement: DrawerProps['placement'] = 'left';
  return <div className="sidebar">
    <Drawer
      placement={placement}
      closable={false}
      visible={visible}
      key={placement}
      onClose={onClose}
    >
      <div className="drawer-header flex justify-between items-center">
        <div className="logo flex ">
          <img src={iconPath + "Logos-rev.svg"} alt="logo-rev icon" />
          <img src={iconPath + "AIP LOGO.svg"} className="ml-4" alt="logo icon" />
        </div>
        <div className="drawer-close-icon text-white cursor-pointer text-sm" onClick={onClose}>
          <img src={iconPath + "close-icon.svg"} alt="Drawer icon" />
        </div>
      </div>
      <Menu
        mode="inline"
        theme="light"
        style={{ height: '100%' }}
        selectedKeys={selectedKeys}
      >
        {
          HEADER_MENU.map((item) => (
            item.subMenu ?
              <Menu.SubMenu key={item.key} icon={<CaretDownOutlined />} title={item.title}>
                {
                  item.subMenu.map((subItem) => (
                    <Menu.Item key={subItem.key} /* onClick={() => { updateSlectedKeys(item.key) }} */>
                      <Link to={subItem.link || ""} onClick={() => { updateSlectedKeys(subItem.key) }}>{subItem.label}</Link>
                    </Menu.Item>
                  ))
                }
              </Menu.SubMenu> :
              <Menu.Item key={item.key} >
                <Link to={item.link} onClick={() => { updateSlectedKeys(item.key) }}>{item.linkLabel}</Link>
              </Menu.Item>
          ))
        }
      </Menu>
    </Drawer>
  </div>;
};

export default SideDrawer;
