import { Spin } from 'antd';
import { SpinSize } from 'antd/lib/spin';
import * as React from 'react';

type LoaderProps = {
  size?: SpinSize
};

const Loader: React.FC<any> = ({size}: LoaderProps) => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <Spin size={size ? size : "default"} />
    </div>
  );
};

export default Loader;
