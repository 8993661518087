import { Alert } from "antd";
import * as React from "react";
import { ALERT_MESSAGE_TYPES, SUCCESS_MESSAGES } from "../../models/constants/Core.Constants";
import { ROUTES } from "../../models/enums/routes";
const iconPath = process.env.PUBLIC_URL + "/images/";

const APMessageStrip: React.FC<any> = ({
  alertMessageInfo,
  setAlertMessgeInfo,
}) => {
  let message = alertMessageInfo?.message?.split("<br>");
  let messageInfo = alertMessageInfo?.message?.split("<br>");
  if (message && message[0] === SUCCESS_MESSAGES.submitJobQueue) {
    message[0] = <>{message[0]}&ensp;&ensp;<a className="text-darkPurple underline hover:text-darkPurple hover:underline" href={ROUTES.JOBS_QUEUE + ROUTES.LIST}>View Jobs List</a></>
  }
  const description = message?.splice(1);
  const messageType: any = alertMessageInfo?.type;
  const message_ref = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    if (alertMessageInfo?.message && message_ref.current != null) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [alertMessageInfo])

  return (
    <>
      <div>
        {alertMessageInfo && alertMessageInfo?.message && message && (
          <div className="app-alert-box mb-5" ref={message_ref}>
            <Alert
              message={<>{message[0]}</>}
              description={<>
                {messageInfo.map((msg: any, i: number)=>{
                  if(i !==0 )
                  {
                    return (<div key={i}>
                      {msg}
                    </div>)
                  }
                })}
              </>}
              type={messageType}
              icon={
                (messageType === ALERT_MESSAGE_TYPES.success ? (
                  <img src={iconPath + "success.svg"} />
                ) : (
                  <img src={iconPath + "ic_error.svg"} />
                ))
              }
              showIcon = {message[0] === "The domain has already been added to this competitive group" ? false : true}
              closable
              afterClose={() => setAlertMessgeInfo({ message: undefined })}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default APMessageStrip;
