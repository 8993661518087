import * as React from "react";
import {
  AlertMessage,
  BreadCrumbInterface,
  TitleInterface,
} from "../../models/interfaces/Common.interface";
import APBreadCrumb from "../apBreadCrumb/apBreadCrumb";
import APMessageStrip from "../apMessageStrip/apMessageStrip";
import PageTitle from "../pageTitle";

type HeaderContextType = {
  titleInfo: TitleInterface | undefined;
  setTitleInfo: (titleInfo: TitleInterface) => void;
  breadCrumbInfo: BreadCrumbInterface[] | undefined;
  setBreadCrumbInfo: (breadCrumb: BreadCrumbInterface[]) => void;
  alertMessageInfo: AlertMessage | undefined;
  setAlertMessgeInfo: (alertMessageInfo: AlertMessage) => void;
};
export const HeaderContext = React.createContext<HeaderContextType>(
  {} as HeaderContextType
);

type HeaderContextProviderProps = {
  children?: React.ReactNode | React.ReactNode[];
};
const useHeaderContext = () => React.useContext(HeaderContext);

const HeaderContextProvider = ({ children }: HeaderContextProviderProps) => {
  const [breadCrumbInfo, setBreadCrumbInfo] = React.useState<
    BreadCrumbInterface[] | undefined
  >();
  const [titleInfo, setTitleInfo] = React.useState<
    TitleInterface | undefined
  >();
  const [alertMessageInfo, setAlertMessgeInfo] = React.useState<
    AlertMessage | undefined
  >();

  return (
    <HeaderContext.Provider
      value={{
        alertMessageInfo,
        setAlertMessgeInfo,
        breadCrumbInfo,
        setBreadCrumbInfo,
        titleInfo,
        setTitleInfo,
      }}
    >
      {breadCrumbInfo && breadCrumbInfo.length > 0 && (
        <APBreadCrumb breadCrumbInfo={breadCrumbInfo} />
      )}
      {alertMessageInfo && (
        <APMessageStrip
          alertMessageInfo={alertMessageInfo}
          setAlertMessgeInfo={setAlertMessgeInfo}
        />
      )}
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContextProvider, useHeaderContext };
