import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ROUTES } from '../../core/models/enums/routes'
import { useHeaderContext } from '../../core/ui/HeaderContextProvider/HeaderContextProvider'

import Jobs from './jobs-queue/Jobs'

const CompetitiveGroups = React.lazy(() => import('./competitive-groups'))
const Domains = React.lazy(() => import('./domains/Domains'))
const ReferenceData = React.lazy(() => import('./reference-data/ReferenceData'))
const DataManagement = React.lazy(() => import('./data-management'))
const GA = React.lazy(() => import('./ga/GA'))

type Props = {}

const FeatureRoutes = (props: Props) => {

  const location = useLocation();
  const { setAlertMessgeInfo } = useHeaderContext();

  React.useEffect(() => {
    setAlertMessgeInfo({
      message: "",
      type: ""
    });
  }, [location]);

  return (
    <>
      <Routes>
        <Route path={ROUTES.COMPETITIVE_GROUPS + ROUTES.ANY_SEPRATOR} element={<CompetitiveGroups />} />
        <Route path={ROUTES.DOMAINS + ROUTES.ANY_SEPRATOR} element={<Domains />} />
        <Route path={ROUTES.JOBS_QUEUE + ROUTES.ANY_SEPRATOR} element={<Jobs />} />
        <Route path={ROUTES.REFERENCE_DATA + ROUTES.ANY_SEPRATOR} element={<ReferenceData />} />
        <Route path={ROUTES.DATA_MANAGEMENT + ROUTES.ANY_SEPRATOR} element={<DataManagement />} />
        <Route path={ROUTES.GA} element={<GA />} />
      </Routes>
    </>
  )
}

export default FeatureRoutes