import React from 'react'
import AppRoutes from './AppRoutes'

const App: React.FC = () => {

  return (
    <div>
      <AppRoutes />
    </div>
  )
}

export default App
