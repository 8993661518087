import { ROUTES } from "../enums/routes";

export class CoreConstants {
    static readonly APP_SECRET_KEY = "C27fiC2A8E4nlCR2fX4c";
}

//values as per backend DB
export const STATUS: any = {
    "N": "New",
    "A": "Active",
    "I": "Inactive"
}

export const DOMAIN_TYPES: any = {
    "Target": "T",
    "Competitor": "C",
    "Other": "O"
}

//values as per backend DB
export const CONFIGUE_STATUS: any = {
    "D": "Draft",
    "M": "Modified",
    "Q": "InQueue",
    "P": "In Process",
    "R": "Ready"
}

export const DOMAIN_CONFIGUE_STATUS: any = {
    "D": "Draft",
    "M": "Modified",
    "Q": "InQueue",
    "P": "In Process",
    "R": "Ready"
}

export const PAGE_SIZE = 8

export const HEADER_MENU = [{
    "key": "competitive-groups",
    "icon": undefined,
    "title": "Competitive Groups",
    "link": ROUTES.COMPETITIVE_GROUPS + ROUTES.LIST,
    "linkLabel": "Competitive Groups",
    "subMenu": undefined
},
{
    "key": "domains",
    "icon": undefined,
    "title": "Domains",
    "link": ROUTES.DOMAINS + ROUTES.LIST,
    "linkLabel": "Domains",
    "subMenu": undefined
},
{
    "key": "jobs-queue",
    "icon": undefined,
    "title": "Jobs",
    "link": ROUTES.JOBS_QUEUE + ROUTES.LIST,
    "linkLabel": "Jobs Queue",
    "subMenu": undefined
},
{
    "key": "reference-data",
    "icon": undefined,
    "title": "Reference Data",
    "link": ROUTES.REFERENCE_DATA,
    "linkLabel": "Reference Data",
    "subMenu": [
        {
            "key": "industries",
            "label": "Industries",
            "link": ROUTES.REFERENCE_DATA + ROUTES.REFERENCE_DATA_INDUSTRIES
        },
        {
            "key": "apax-sectors",
            "label": "Apax Sectors",
            "link": ROUTES.REFERENCE_DATA + ROUTES.REFERENCE_DATA_APAX_SECTORS
        },
        {
            "key": "business-models",
            "label": "Business Models",
            "link": ROUTES.REFERENCE_DATA + ROUTES.REFERENCE_DATA_BUSINESS_MODELS
        },
        {
            "key": "funds",
            "label": "Funds",
            "link": ROUTES.REFERENCE_DATA + ROUTES.REFERENCE_DATA_FUNDS
        },
        {
            "key": "google-analytics-goals",
            "label": "Google Analytics Goals",
            "link": ROUTES.REFERENCE_DATA + ROUTES.REFERENCE_GOOGLE_ANALYTICS_GOALS
        },
        {
            "key": "google-analytics-events",
            "label": "Google Analytics Events",
            "link": ROUTES.REFERENCE_DATA + ROUTES.REFERENCE_GOOGLE_ANALYTICS_EVENTS
        },
        {
            "key": "tags",
            "label": "Tags",
            "link": ROUTES.REFERENCE_DATA + ROUTES.REFERENCE_DATA_TAGS
        },
        {
            "key": "channel-mapping",
            "label": "Channel Mapping",
            "link": ROUTES.REFERENCE_DATA + ROUTES.REFERENCE_DATA_CHANNEL_MAPPING
        }
    ]
},
{
    "key": "data-management",
    "icon": undefined,
    "title": "Data Source Management",
    "link": ROUTES.DATA_MANAGEMENT + ROUTES.LIST,
    "linkLabel": "Data Management",
    "subMenu": undefined
},
]

export const DATE_FORMATE = 'DD-MMM YY'
export const DATE_TIME_FORMATE = 'DD-MMM YY hh:mm a'
export const ALERT_MESSAGE_TYPES = {
    success: "success",
    error: "error"
}

export const HEADER_NAME_KEYS = {
    referenceData: "reference-data"
}

export const SUCCESS_MESSAGES = {
    submitJobQueue: "Your competitive group has been submitted to the Jobs Queue and is waiting to be processed"
}

export const customStyles = {
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#7450B2' : state.isFocused && "rgba(173, 129, 234, 0.08)",
        "&:hover": {
            backgroundColor: !(state.isSelected) && "rgba(173, 129, 234, 0.08)",
        },
        ':active': {
            ...provided[':active'],
            backgroundColor: state.isSelected && '#7450B2',
        }
    }),
}

export const DOMAIN_STATUS_BACKEND = {
    new: "N",
    active: "A",
    inactive: "I"
}

export const DOMAIN_STATUS = {
    new: "New",
    active: "Active",
    inactive: "Inactive"
}

export const DOMAIN_CONFIGUE_STATUS_BACKEND = {
    inQueue: "Q",
    inProgress: "P",
    completed: "C",
    failed: "F",
    draft: "D",
    ready: "R",
    modified: "M",
}

export const DOMAIN_CONFIGUE_STATUS_VALUE = {
    inQueue: "In queue",
    inProgress: "In progress",
    completed: "Completed",
    failed: "Failed",
    draft: "Draft",
    ready: "Ready",
    modified: "Modified",
}

export const GA_INFO = {
    scope: "https://www.googleapis.com/auth/analytics.edit"
}
