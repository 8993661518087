
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { CaretDownOutlined, LoginOutlined } from '@ant-design/icons';
import { Affix, Menu } from 'antd';
import SideDrawer from "../Drawer";
import { HEADER_MENU, HEADER_NAME_KEYS } from "../../../../core/models/constants/Core.Constants";
import { ROUTES } from "../../../../core/models/enums/routes";
const iconPath = process.env.PUBLIC_URL + '/images/';

type HeaderProps = {
  //
};

const Header: React.FC<HeaderProps> = () => {
  const top = 0;
  const [visible, setVisible] = React.useState(false);
  const [selectedKeys, setSelectedKeys] = React.useState(["competitive-groups"])
  const name = sessionStorage.getItem("name");
  const location = useLocation()

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = ROUTES.LOGIN;
  }

  const updateSlectedKeys = (value: string) => {
    setSelectedKeys([value])
    setVisible(false)
  }

  React.useEffect(() => {
    let key = HEADER_MENU.find((item) => {
      return window.location.pathname.includes(item.link)
    })
    if(key?.key === HEADER_NAME_KEYS.referenceData)
    {
      const subMenuKey = key?.subMenu?.find((item)=>{
        return window.location.pathname.includes(item.link)
      })
      subMenuKey && setSelectedKeys([subMenuKey?.key])
    } else
    {
      key && setSelectedKeys([key?.key])
    }
  }, [location])

  return <>
    <Affix offsetTop={top}>
      <header className="header-section pt-4 px-4 sm:pb-0 pb-4">
        <div className="header-top flex items-center justify-between">
          <div className="header-left flex">
            <div className="header-left-menu cursor-pointer" onClick={showDrawer}>
              <img src={iconPath + "ic_menu.svg"} alt="ic_menu icon" />
            </div>
            <div className="header-logo sm:hidden flex ml-3">
              <img src={iconPath + "Logos-rev.svg"} alt="Logos-rev icon" />
            </div>
            <div className="header-text whitespace-nowrap items-center ml-5 text-base leading-5 font-normal text-white sm:flex hidden">
              <img src={iconPath + "Apax - squares.svg"} className="mr-3" alt="squares icon" />  Digital Insights Platform Admin
            </div>
          </div>
          <div className="header-logo sm:flex hidden">
            <img src={iconPath + "Logos-rev.svg"} alt="Logos-rev icon" />
          </div>
          <div className="header-right flex items-center">
            <div className="header-user-name">
              <Menu mode="horizontal" defaultSelectedKeys={['mail']} theme='dark' >
                <Menu.SubMenu key="SubMenu" title={name} icon={<CaretDownOutlined />}>
                  <Menu.Item key="two" icon={<LoginOutlined />} onClick={handleLogOut}>
                    Log Out
                  </Menu.Item>
                </Menu.SubMenu>
              </Menu>
            </div>
          </div>
        </div>
        <div className="header-text whitespace-nowrap items-center text-base leading-5 font-normal text-white sm:hidden flex pt-5 pb-0.4">
          <img src={iconPath + "Apax - squares.svg"} className="mr-3" alt="squares icon" />  Digital Insights Platform Admin
        </div>

        <div className="header-menu pt-2 px-8">
          <Menu mode="horizontal" selectedKeys={selectedKeys}>
            {
              HEADER_MENU.map((item) => (
                item.subMenu ?
                  <Menu.SubMenu key={item.key} icon={<CaretDownOutlined />} title={item.title}>
                    {
                      item.subMenu.map((subItem) => (
                        <Menu.Item key={subItem.key} onClick={() => { updateSlectedKeys(item.key) }}>
                          <Link to={subItem.link || ""} onClick={() => { updateSlectedKeys(subItem.key) }}>{subItem.label}</Link>
                        </Menu.Item>
                      ))
                    }
                  </Menu.SubMenu> :
                  <Menu.Item key={item.key} >
                    <Link to={item.link} onClick={() => { updateSlectedKeys(item.key) }}>{item.linkLabel}</Link>
                  </Menu.Item>
              ))
            }
          </Menu>
        </div>
      </header>
    </Affix>
    <SideDrawer visible={visible} onClose={onClose} updateSlectedKeys={updateSlectedKeys} selectedKeys={selectedKeys} />
  </>;
};

export default Header;
